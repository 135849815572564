import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";

export default {
  async loadRegesterFormPaginated(context, payload) {
    await NetworkManager.post(
      context,
      `register-form/find-all-paginated`,
      payload,
      "PaginationResponse",
      "setPaginatedRegistration",
      Parser
    );
  },
  async loadRegistrFormColumnsNames(context){
    await NetworkManager.get(
      context,
      `register-form-column-name`,
      "RegisterFormColumnsNames",
      "setRegisterFormColumnsNames",
      Parser
    );
  },
  async registerFormColumnsChangeStatus(context , payload){
    await NetworkManager.put(
      context,
      `register-form-column-name/active-status`,
      payload,
      "RegisterFormColumnsNames",
      null,
      Parser
    );
  },
  async deleteItem(context, payload) {
    await NetworkManager.put(
      context,
      `/register-form/delete-item`,
      payload,
      null,
      "deleteItem",
      Parser
    );
  },

  async downloadExcelSheet() {
    return await NetworkManager.download(`/register-form/export`);
  }
}
