// import { Post } from "../models";

export default {
  setPaginatedRegistration(state, payload) {
    state.registerFormPaginated = payload;
  },
  setRegisterFormColumnsNames(state,payload){
    state.registerFormColumnsNames = payload;
  },
  deleteItem(state, payload) {
    let index = state.registerFormPaginated.result.findIndex(
      (x) => x.id == payload.id
    );
    state.registerFormPaginated.result.splice(index, 1);
  },
}